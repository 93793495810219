export default {
  siteTitle: 'Get bonus',
  blogerName: 'TASPIO',
  socialsList: [
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/taspio'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCUp6wodtd6vPPj1ZmFfVoIQ'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/taspio/'
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/c7fff53805',
      gameTitle: 'Burning Chilli X',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/ce7dff076',
      gameTitle: 'Alien Fruits',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'starda',
      url: 'https://strd-irrs10.com/c0b948451',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/cf3fcf1a2',
      gameTitle: 'LEGZO PUNK',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/ced632998',
      gameTitle: 'IZZI ART',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/ce2744f57',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/cb9080417',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c0d63e591',
      gameTitle: 'Jet Air',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>TASPIO</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'TASPIO',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
